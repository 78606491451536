import { California } from "./California";
import { XF } from "./XF";
import { clamp } from "./common/clamp";
import { gtmEditorAlttext } from "./common/gtm-ids";
import {
  guestAttachButton,
  guestImageButton,
  insertImageByURLButton,
  oneClickImageUploadButton,
} from "./froala-custom-buttons/custom-buttons";

California.editorButton = {
  init() {
    editorButton.initializeDialog();

    if ($.FE.COMMANDS.xfCustom_trigger_attachment) {
      $.FE.COMMANDS.xfCustom_trigger_attachment.callback =
        editorButton.callback;
    }
  },

  initializeDialog() {
    // empty but required function
  },

  callback() {
    $("[data-id=upload-input]")[0].click();
  },
};
const { editorButton } = California;

$(document).on("editor:first-start", California.editorButton.init);

XF.Element.extend("editor", {
  invalidAttachmentsFiles: [],
  elAltWrapper: document.querySelector<HTMLDivElement>(".alt-wrapper"),

  init() {
    if (!this.$target.is("textarea")) {
      console.error("Editor can only be initialized on a textarea");
      return;
    }

    this.$target.trigger("editor:start", [this]);

    this.$form = this.$target.closest("form");
    if (!this.$form?.length) {
      this.$form = null;
    }

    if (this.options.attachmentTarget) {
      const $attachManager = this.$target.closest(
        "[data-xf-init~=attachment-manager]"
      );
      const $uploader = $attachManager.find(this.options.attachmentUploader);
      this.uploadUrl = $uploader.attr("href");
    }

    this.usingStandardConfig =
      this.$target.siblings("#usingStandardConfig").val() === "1";

    if (!this.options.deferred) {
      this.startInit({
        afterInit: (xf_editor, froala_editor) => {
          if (this.usingStandardConfig) {
            oneClickImageUploadButton.init(xf_editor, froala_editor);
            insertImageByURLButton.init(xf_editor, froala_editor);
          }
        },
      });
    }
  },
  removeEditorButtonById(
    id: string,
    buttonConfigurations: { [key: string]: string[] }
  ) {
    Object.values(buttonConfigurations).forEach((buttonConfiguration) => {
      if (buttonConfiguration) {
        const buttonIndex = buttonConfiguration.indexOf(id);
        // eslint-disable-next-line eqeqeq
        if (buttonIndex != -1) {
          buttonConfiguration.splice(buttonIndex, 1);
        }
      }
    });
    return buttonConfigurations;
  },
  getEditorConfig() {
    const fontSize = ["9", "10", "12", "15", "17", "22", "26"];
    const fontFamily = {
      arial: "Arial",
      "'book antiqua'": "Book Antiqua",
      "'courier new'": "Courier New",
      georgia: "Georgia",
      tahoma: "Tahoma",
      "'times new roman'": "Times New Roman",
      "'trebuchet ms'": "Trebuchet MS",
      verdana: "Verdana",
    };

    const heightLimits = this.getHeightLimits();

    let config = {
      direction: $.FE.LANGUAGE.xf.direction,
      editorClass: "bbWrapper", // since this is a BB code editor, we want our output to normalize like BB code
      fileUpload: false,
      fileMaxSize: 4 * 1024 * 1024 * 1024, // 4G
      fileUploadParam: "upload",
      fileUploadURL: false,
      fontFamily,
      fontSize,
      heightMin: heightLimits[0],
      heightMax: heightLimits[1],
      htmlAllowedTags: [
        "a",
        "b",
        "bdi",
        "bdo",
        "blockquote",
        "br",
        "cite",
        "code",
        "dfn",
        "div",
        "em",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "hr",
        "i",
        "img",
        "li",
        "mark",
        "ol",
        "p",
        "pre",
        "s",
        "script",
        "style",
        "small",
        "span",
        "strike",
        "strong",
        "sub",
        "sup",
        "table",
        "tbody",
        "td",
        "tfoot",
        "th",
        "thead",
        "time",
        "tr",
        "u",
        "ul",
        "var",
        "video",
        "wbr",
      ],
      key: "5D5C4B4B3aG3C2A5A4C4E3E2D4F2G2tFOFSAGLUi1AVKd1SN==",
      htmlAllowComments: false,
      imageUpload: false,
      imageCORSProxy: null,
      imageDefaultDisplay: "inline",
      imageDefaultWidth: "auto",
      imageEditButtons: [
        "imageAlign",
        "imageSize",
        "imageAlt",
        "|",
        "imageReplace",
        "imageRemove",
        "|",
        "imageLink",
        "linkOpen",
        "linkEdit",
        "linkRemove",
      ],
      imageManagerLoadURL: false,
      imageMaxSize: 4 * 1024 * 1024 * 1024, // 4G
      imagePaste: false,
      imageResize: true,
      imageUploadParam: "upload",
      imageUploadRemoteUrls: false,
      imageUploadURL: false,
      language: "xf",
      linkAlwaysBlank: true,
      linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
      linkInsertButtons: ["linkBack"],
      placeholderText: "",
      tableResizer: false,
      tableEditButtons: [
        "tableHeader",
        "tableRemove",
        "|",
        "tableRows",
        "tableColumns",
      ],
      toolbarBottom: true,
      toolbarSticky: false,
      videoAllowedTypes: ["mp4", "quicktime", "ogg", "webm"],
      videoAllowedProviders: [],
      videoDefaultAlign: "left",
      videoDefaultDisplay: "inline",
      videoDefaultWidth: 500,
      videoEditButtons: [
        "videoReplace",
        "videoRemove",
        "|",
        "videoAlign",
        "videoSize",
      ],
      videoInsertButtons: ["videoBack", "|", "videoUpload"],
      videoMaxSize: 4 * 1024 * 1024 * 1024, // 4G
      videoMove: true,
      videoUpload: false,
      videoUploadParam: "upload",
      videoUploadURL: false as boolean | string,
      zIndex: XF.getElEffectiveZIndex(this.$target) + 1,
      xfBbCodeAttachmentContextInput: this.options.attachmentContextInput,
    };
    $.FE.DT = true;

    // fas = solid, far = regular, fal = light
    $.FE.DefineIconTemplate(
      "xf_font_awesome_5",
      `<i class="fa${XF.config.fontAwesomeWeight} fa-[NAME]" aria-hidden="true"></i>`
    );
    $.FE.DefineIconTemplate(
      "fora_icon",
      `<svg width="15" height="15" viewBox="2 1 15 15">
        <image xlink:href="/styles/default/icon-library/[NAME].svg" width="18" height="18"></image>
      </svg>`
    );
    $.FE.ICON_DEFAULT_TEMPLATE = "xf_font_awesome_5";

    // FA5 overrides
    $.FE.DefineIcon("insertVideo", { NAME: "video-plus" });
    $.FE.DefineIcon("undo", { NAME: "undo" });
    $.FE.DefineIcon("redo", { NAME: "redo" });
    $.FE.DefineIcon("tableHeader", { NAME: "heading" });

    this.applyCustomConfig();

    let buttonConfigurations = this.getButtonConfig();

    if (
      typeof this.$target.data("show-gallery") !== "undefined" &&
      !this.$target.data("show-gallery")
    ) {
      buttonConfigurations = this.removeEditorButtonById(
        "xfCustom_gallery",
        buttonConfigurations
      );
    }

    if (this.uploadUrl) {
      const uploadParams = {
        _xfToken: XF.config.csrf,
        _xfResponseType: "json",
        _xfWithData: 1,
      };

      config.fileUpload = true;
      config.fileUploadParams = uploadParams;
      config.fileUploadURL = this.uploadUrl;

      config.imageUpload = true;
      config.imageUploadParams = uploadParams;
      config.imageUploadURL = this.uploadUrl;
      config.imagePaste = true;

      config.videoUpload = true;
      config.videoUploadParams = uploadParams;
      config.videoUploadURL = this.uploadUrl;
    } else {
      // TODO: we can return the below value once Froala fix issue #2987
      // config.imageInsertButtons = ['imageByURL'];
      config.imageInsertButtons = ["imageUpload", "imageByURL"];
      // Remove the xfCustom_trigger_attachment if the uploadUrl isn't present
      buttonConfigurations = this.removeEditorButtonById(
        "xfCustom_trigger_attachment",
        buttonConfigurations
      );
    }

    config = $.extend({}, config, buttonConfigurations);

    this.$target.trigger("editor:config", [config, this]);

    return config;
  },
  startInit(callbacks?: Parameters<typeof XF.Editor.startInit>[0]) {
    const cbBefore = callbacks && callbacks.beforeInit;
    const cbAfter = callbacks && callbacks.afterInit;

    this.$target
      .css("visibility", "")
      .on("froalaEditor.initialized", (m, ed) => {
        this.ed = ed;

        if (cbBefore) {
          cbBefore(this, ed);
        }

        this.editorInit();
        this.altInputInit();

        if (cbAfter) {
          cbAfter(this, ed);
        }
      })
      .froalaEditor(this.getEditorConfig());

    function allowExtension(filename: string) {
      const allowExts = XF.config.attachmentExtensions.split(",");
      const re = /(?:\.([^.]+))?$/;
      let ext = re.exec(filename)?.[1];
      if (ext) {
        ext = ext.toLowerCase();
        return allowExts.indexOf(ext) !== -1;
      }
      return false;
    }

    const checkInvalidFile = (file, index) => {
      if (this.invalidAttachmentsFiles.indexOf(index) > -1) {
        return false;
      }
      return undefined;
    };

    const checkUploadFiles = (files: File[]) => {
      let message = "";
      let largeFiles = "";
      let invalidFiles = "";
      let extraFiles = "";

      this.invalidAttachmentsFiles = [];

      let currentAttachments = $(
        ".attachUploadList>.js-attachmentFile:not(.is-uploadError)"
      ).length;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (currentAttachments >= XF.config.attachmentMaxPerMessage) {
          extraFiles += `<br/>${file.name}`;
          this.invalidAttachmentsFiles.push(i);
        } else if (file.size > XF.config.uploadMaxFilesize) {
          largeFiles += `<br/>${file.name}`;
          this.invalidAttachmentsFiles.push(i);
        } else if (file.name && !allowExtension(file.name)) {
          invalidFiles += `<br/>${file.name}`;
          this.invalidAttachmentsFiles.push(i);
        } else {
          currentAttachments++;
        }
      }

      if (largeFiles) {
        message += `Your files upload exceeded the maximum limit of ${Math.floor(
          parseInt(XF.config.uploadMaxFilesize, 10) / 1024 / 1024
        )}MB.${largeFiles}`;
      }

      if (invalidFiles) {
        message += `${
          message ? "<br/><br/>" : ""
        }Your following files do not have an allowed extension.${invalidFiles}`;
      }

      if (extraFiles) {
        message += `${
          message ? "<br/><br/>" : ""
        }Your following files exceed the maximum numbers of attachmets. You may attach ${
          XF.config.attachmentMaxPerMessage
        } attachments.${extraFiles}`;
      }
      if (message) {
        XF.alert(message);
      }
    };

    this.ed.events.on("file.checkAllFiles", (files: File[]) => {
      return checkUploadFiles(files);
    });

    this.ed.events.on("image.checkAllFiles", (files: File[]) => {
      return checkUploadFiles(files);
    });

    this.ed.events.on("file.beforeEachUpload", checkInvalidFile);

    this.ed.events.on("image.beforeEachUpload", checkInvalidFile);
  },

  setupUploads() {
    this.ed.events.on("file.uploaded", (response) => {
      this.ed.popups.hide("file.insert");
      this.ed.events.focus();
      return this.handleUploadSuccess(response, null, (uploadResponse) => {
        return uploadResponse.status === "ok";
      });
    });

    this.ed.events.on("file.error", (details, response) => {
      this.ed.popups.hide("file.insert");
      this.handleUploadError(details, response);
      this.ed.events.focus();
      return false;
    });

    if (!this.uploadUrl) {
      this.ed.events.on("image.beforeUpload", () => {
        return false; // prevent uploading
      });
      this.ed.events.on("file.beforeUpload", () => {
        return false; // prevent uploading
      });
      this.ed.events.on("video.beforeUpload", () => {
        return false; // prevent uploading
      });
    }

    this.ed.events.on("image.error", (details, response) => {
      if (!response) {
        return undefined; // not an uploaded image
      }

      this.ed.popups.hide("image.insert");
      this.handleUploadError(details, response);
      return false;
    });

    this.ed.events.on("video.error", (details, response) => {
      if (!response) {
        return undefined; // not an uploaded image
      }

      this.ed.popups.hide("video.insert");
      this.handleUploadError(details, response);
      return false;
    });

    this.ed.events.on("image.uploaded", (response) => {
      const onError = () => {
        this.ed.image.remove();
        this.ed.popups.hide("image.insert");
        this.ed.events.focus();
        return false;
      };

      function onSuccess(ret) {
        return ret.status === "ok";
      }

      return this.handleUploadSuccess(response, onError, onSuccess);
    });

    this.ed.events.on("video.uploaded", (response) => {
      const onError = () => {
        this.ed.video.remove();
        this.ed.popups.hide("video.insert");
        this.ed.events.focus();
        return false;
      };

      function onSuccess() {
        return true;
      }

      return this.handleUploadSuccess(response, onError, onSuccess);
    });

    function videoImageInsert($el, response) {
      if (!response) {
        return;
      }

      let json;
      try {
        json = JSON.parse(response);
      } catch (e) {
        return;
      }

      if ($el.hasClass("fr-video")) {
        const $video = $el.find("video");

        $video.attr("data-xf-init", "video-init").attr("style", "").empty();

        // eslint-disable-next-line no-param-reassign
        $el = $video;
      }

      if (json.attachment) {
        // clean up the data attributes that were added from our JSON response
        const id = json.attachment.attachment_id;
        const attrs = $el[0].attributes;
        const re = /^data-(?!xf-init)/;
        for (let i = attrs.length - 1; i >= 0; i--) {
          if (re.test(attrs[i].nodeName)) {
            $el.removeAttr(attrs[i].nodeName);
          }
        }

        $el.attr("data-attachment", `full:${id}`);
      }
    }

    this.ed.events.on("image.inserted video.inserted", videoImageInsert);
    this.ed.events.on("image.replaced video.replaced", videoImageInsert);

    this.ed.events.on("image.loaded", ($img) => {
      // try to prevent automatic editing of an image once inserted

      if (!this.ed.popups.isVisible("image.edit")) {
        // ... but not if we're not in the edit mode
        return;
      }

      const $editorImage = this.ed.image.get();
      // eslint-disable-next-line eqeqeq
      if (!$editorImage || $editorImage[0] != $img[0]) {
        // ... and only if it's for this image
        return;
      }

      this.ed.image.exitEdit(true);

      const range = this.ed.selection.ranges(0);
      range.setStartAfter($img[0]);
      range.collapse(true);

      const selection = this.ed.selection.get();
      selection.removeAllRanges();
      selection.addRange(range);

      this.ed.events.focus();
      this.scrollToCursor();
    });

    this.ed.events.on("video.loaded", ($video) => {
      // try to prevent automatic editing of a video once inserted

      if (!this.ed.popups.isVisible("video.edit")) {
        // ... but not if we're not in the edit mode
        return;
      }

      const $editorVideo = this.ed.video.get();
      // eslint-disable-next-line eqeqeq
      if (!$editorVideo || $editorVideo[0] != $video[0]) {
        // ... and only if it's for this video
        return;
      }

      this.ed.events.trigger("video.hideResizer");
      this.ed.popups.hide("video.edit");

      const range = this.ed.selection.ranges(0);
      range.setStartAfter($video[0]);
      range.collapse(true);

      const selection = this.ed.selection.get();
      selection.removeAllRanges();
      selection.addRange(range);

      this.ed.events.focus();
      this.scrollToCursor();
    });

    this.ed.events.on("popups.show.image.edit", () => {
      const $editorImage = this.ed.image.get();

      if (!$editorImage.length || !$editorImage.hasClass("smilie")) {
        return;
      }

      this.ed.image.exitEdit(true);
      this.ed.selection.save();

      setTimeout(() => {
        this.ed.selection.restore();
      }, 0);
    });

    // External upsell buttons to call editor's action
    this.ed.events.bindClick(
      $("#upsell-button-insert-image"),
      undefined,
      () => {
        this.ed.image.showInsertPopup();
      }
    );
  },

  // #region hacky alt text input
  altInputInit() {
    const elAltWrapper = this.ed.el
      .closest(".editor-wrapper")
      ?.querySelector(".alt-wrapper") as HTMLDivElement | null;
    if (elAltWrapper) {
      const elFrWrapper = this.ed.el.parentElement as HTMLElement;
      elFrWrapper.appendChild(elAltWrapper);
      const syncAltInputs = () => {
        const rectEd = this.ed.el.getBoundingClientRect();

        const imgs = Array.from(
          (this.ed.el as HTMLDivElement).querySelectorAll<
            HTMLImageElement & {
              elAlt?: HTMLInputElement | HTMLButtonElement;
            }
          >(":scope > p > img.fr-dii:not(.smilie)")
        );
        const alts = elAltWrapper.querySelectorAll<
          HTMLInputElement & { elImg: HTMLImageElement }
        >("input, button");

        // remove inputs attached to images that are no longer present
        alts.forEach((i) => {
          if (!imgs.includes(i.elImg)) {
            i.remove();
          }
        });

        imgs.forEach((elImg) => {
          // create input
          if (!elImg.elAlt) {
            const elAlt = document.createElement(
              "button"
            ) as HTMLButtonElement & { elImg?: HTMLImageElement };
            elAlt.type = "button";
            elAlt.className = "button button--outlined";
            elAlt.dataset.gtm = gtmEditorAlttext;
            elImg.elAlt = elAlt;
            elAlt.elImg = elImg;
            elAlt.addEventListener("click", () => {
              const elInput = document.createElement(
                "input"
              ) as HTMLInputElement & { elImg?: HTMLImageElement };
              elInput.type = "text";
              elInput.className = "input";
              elInput.style.display = elAlt.style.display;
              elInput.style.top = elAlt.style.top;
              elInput.style.left = elAlt.style.left;
              elInput.style.maxWidth = elAlt.style.maxWidth;
              elInput.elImg = elAlt.elImg;
              elInput.placeholder = elAltWrapper.dataset.placeholder || "";
              elAlt.replaceWith(elInput);
              elImg.elAlt = elInput;
              elInput.addEventListener("focus", () => {
                elInput.value = elImg.alt;
              });
              elInput.addEventListener("change", () => {
                elImg.alt = elInput.value;
              });
              elInput.addEventListener("keypress", (event) => {
                if (event.key === "Enter") event.preventDefault();
              });
              elInput.value = elImg.alt;
              requestAnimationFrame(() => {
                elInput.focus();
              });
            });
            elAltWrapper.appendChild(elAlt);
          }
          if (elImg.elAlt.tagName === "BUTTON") {
            elImg.elAlt.textContent = elImg.alt
              ? "Edit description"
              : "Add description";
            elImg.elAlt.title = elImg.elAlt.textContent;
            const icon = document.createElement("i");
            icon.className = "fa--xf far fa-file-alt";
            elImg.elAlt.prepend(icon);
          }

          // sync input with image
          const onLoad = () => {
            const { elAlt } = elImg;
            if (!elAlt) return;
            const margin = 12;
            const rectImg = elImg.getBoundingClientRect();
            elAlt.style.display = `block`;
            const posTarget =
              this.ed.el.parentElement.scrollTop +
              this.ed.el.parentElement.clientHeight -
              elAlt.clientHeight -
              margin;
            const posTop = rectImg.top - rectEd.top + margin;
            const posBottom =
              rectImg.bottom - rectEd.top - elAlt.clientHeight - margin;
            elAlt.style.top = `${clamp(posTop, posTarget, posBottom)}px`;
            elAlt.style.left = `${rectImg.left - rectEd.left + margin}px`;
            elAlt.style.maxWidth = `${Math.max(
              rectImg.width - margin * 2,
              0
            )}px`;
          };
          if (elImg.complete) {
            onLoad();
          } else {
            elImg.addEventListener("load", onLoad);
          }
        });
      };

      this.ed.events.on("contentChanged input", syncAltInputs);
      this.ed.el.parentElement.addEventListener("scroll", syncAltInputs, {
        passive: true,
      });
      window.addEventListener("resize", syncAltInputs, { passive: true });
      elFrWrapper.closest("form")?.addEventListener("submit", () => {
        elAltWrapper.querySelectorAll("input").forEach((i) => i.remove());
      });
    }
  },

  applyCustomConfig() {
    // only apply the new configs if the use-standard-editor-config feature toggle is enabled
    if (!this.usingStandardConfig) return;
    // register custom buttons
    $.FE.DefineIcon("guestImage", { NAME: "image" });
    $.FE.RegisterCommand("guestImage", guestImageButton);
    $.FE.DefineIcon("guestAttach", { NAME: "paperclip" });
    $.FE.RegisterCommand("guestAttach", guestAttachButton);
    $.FE.DefineIcon("oneClickImage", { NAME: "image" });
    $.FE.RegisterCommand("oneClickImage", oneClickImageUploadButton);
    $.FE.DefineIcon("insertImageURL", {
      NAME: "Hotlink",
      template: "fora_icon",
    });
    $.FE.RegisterCommand("insertImageURL", insertImageByURLButton);

    // Remove the option to add a link to images in editor
    $.FE.COMMANDS.imageLink = null;
  },
  // #endregion
});

import { XF } from "../XF";

type FroalaEditor = (typeof XF)["Editor"]["ed"];
type AfterInitCallback = NonNullable<
  Parameters<(typeof XF)["Editor"]["startInit"]>[0]
>["afterInit"];

const guestImageButton = {
  title: "Insert Image",
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback() {
    XF.toast("Log in or sign up to add an image to your post.");
  },
};

const guestAttachButton = {
  title: "Attachment",
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback() {
    XF.toast("Log in or sign up to add a file to your post.");
  },
};

const initOneClickImageUploadButton: AfterInitCallback = (_, ed) => {
  const input = oneClickImageUploadButton.findImageInput(ed);
  if (input) input.addEventListener("change", onFileChanged.bind(ed));
};

const oneClickImageUploadButton = {
  title: "Insert Image",
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  findImageInput(editor: FroalaEditor) {
    const inputs = editor.$oel.siblings("#imageInput");
    if (inputs.length === 1) {
      return inputs[0] as HTMLInputElement;
    }
    return null;
  },
  callback(this: FroalaEditor) {
    const input = oneClickImageUploadButton.findImageInput(this);
    if (input) input.showPicker();
  },
  init: initOneClickImageUploadButton,
};

function onFileChanged(this: FroalaEditor, event: Event) {
  const input = event.target as HTMLInputElement;
  if (!input.files || input.files.length === 0) {
    return;
  }

  const filesToupload = [];

  for (let i = 0; i < input.files.length; i++) {
    const file = input.files[i];

    const fileExtension = file.type.split("/")[1];
    if (!this.opts.imageAllowedTypes.includes(fileExtension)) {
      XF.toast(`${fileExtension} is not an allowed file type`);
      continue;
    }

    filesToupload.push(file);
  }

  if (filesToupload.length > 0) {
    this.image.upload(filesToupload);
    input.value = "";
  }
}

const initInsertImageByURLButton: AfterInitCallback = (_, ed) => {
  ed.opts.imageInsertButtons = ["imageByURL"];
};

const insertImageByURLButton = {
  title: "Insert Image by URL",
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  callback(this: FroalaEditor) {
    this.image.showInsertPopup();
    const popup = this.popups.get("image.insert");
    const parentBtn = this.$tb.find("#xfInsert-1");
    if (parentBtn.length === 1 && popup.length === 1) {
      const leftOffset = parentBtn[0].offsetLeft;
      const topOffset = parentBtn[0].offsetTop;
      const childWidth = popup.width() ?? 320;
      const childHeight = popup.height() ?? 150;
      popup.css("left", leftOffset - childWidth / 2);
      popup.css("top", topOffset - childHeight * 1.2);
    }
  },
  init: initInsertImageByURLButton,
};

export {
  guestAttachButton,
  guestImageButton,
  insertImageByURLButton,
  oneClickImageUploadButton,
};
